import React, {Component} from 'react';
import "../../assets/scss/theme.scss"

class Light extends Component {
    render() {
        return (
            <React.Fragment></React.Fragment>
        );
    }
}

export default Light;