import React, {Component} from 'react';
import {Alert, Button, Col, Input, Modal, Row} from "reactstrap";
import {Switch, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {AvField, AvForm} from "availity-reactstrap-validation"
import {createCar} from "../../store/car/actions";
import {changePassword} from "../../helpers/backend_calls";

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openModal: false,
            error:false,
            success:false,
            errorMessage:""
        }
    }

    init = () => {
        this.setState({
            openModal: true,
            error:false,
            success:false,
            errorMessage:""
        })
    }
    toggle = () => {
        this.setState({
            openModal: !this.state.openModal
        })
    }

    handleValidSubmit = (event, values) => {
        let body = {...values,id: this.props.id}
        changePassword(body).then(response => {
            this.setState({ error:false, errorMessage:"",success:true})
        }).catch(error=>{
            this.setState({ error:true, errorMessage:error.response.data.message})
        })
    }


    render() {
        return (
            <React.Fragment>
                <Button color={"info"} className={"btn-sm"} onClick={this.init}><i
                    className="mdi mdi-pencil align-middle me-2"/>{this.props.t("Change password")}</Button>
                <Modal isOpen={this.state.openModal} size={"md"} toggle={this.toggle} centered backdrop>
                    <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>
                        <div className="modal-header">
                            <h5 className="modal-title mt-0">
                                {this.props.t("Change password")}
                            </h5>
                            <button
                                type="button"
                                onClick={this.toggle}
                                className="close"
                                data-dismiss="modal"
                                aria-label={this.props.t("Close")}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <Row>
                                {this.state.error &&
                                    <Col md={12}>
                                        <Alert color={"danger"} >{this.props.t(this.state.errorMessage)}</Alert>
                                    </Col>}
                                {this.state.success &&
                                    <Col md={12}>
                                        <Alert >{this.props.t("Password was successfully changed")}</Alert>
                                    </Col>}
                            </Row>
                            {!this.state.success &&
                            <Row>
                                <Col md={12}>
                                        <AvField name="oldPassword"
                                                 label={this.props.t("Old password")}
                                                 className="form-control"
                                                 type="password"
                                                 validate={{
                                                     pattern:{
                                                         value:'^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d).*$',
                                                         errorMessage: 'Password must have at least one uppercase, one lowercase letter and one number'
                                                     },
                                                     minLength: {
                                                         value: 8,
                                                         errorMessage: 'Your name must be between 8 and 255 characters'
                                                     },
                                                     maxLength: {
                                                         value: 255,
                                                         errorMessage: 'Your name must be between 8 and 255 characters'
                                                     },
                                                     required: {
                                                         value: true,
                                                         errorMessage: 'Please enter your password'
                                                     }
                                                 }}
                                                 required
                                        />
                                </Col>
                                <Col md={12}>
                                    <AvField name="newPassword"
                                             label={this.props.t("New password")}
                                             className="form-control"
                                             type="password"
                                             validate={{
                                                 pattern:{
                                                     value:'^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d).*$',
                                                     errorMessage: 'Password must have at least one uppercase, one lowercase letter and one number'
                                                 },
                                                 minLength: {
                                                     value: 8,
                                                     errorMessage: 'Your name must be between 8 and 255 characters'
                                                 },
                                                 maxLength: {
                                                     value: 255,
                                                     errorMessage: 'Your name must be between 8 and 255 characters'
                                                 },
                                                 required: {
                                                     value: true,
                                                     errorMessage: 'Please enter your password'
                                                 }
                                             }}
                                             required
                                    />
                                </Col>
                                <Col md={12}>
                                    <AvField name="newPasswordConfirm"
                                             label={this.props.t("New password confirm")}
                                             className="form-control"
                                             type="password"
                                             validate={{
                                                 pattern:{
                                                     value:'^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d).*$',
                                                     errorMessage: 'Password must have at least one uppercase, one lowercase letter and one number'
                                                 },
                                                 minLength: {
                                                     value: 8,
                                                     errorMessage: 'Your name must be between 8 and 255 characters'
                                                 },
                                                 maxLength: {
                                                     value: 255,
                                                     errorMessage: 'Your name must be between 8 and 255 characters'
                                                 },
                                                 required: {
                                                     value: true,
                                                     errorMessage: 'Please enter your password'
                                                 }
                                             }}
                                             required
                                    />
                                </Col>
                            </Row>}
                        </div>
                        {!this.state.success &&
                        <div className="modal-footer">
                            <Button color={"success"} type={"submit"}>{this.props.t("Save")}</Button>
                            <Button color={"secondary"} onClick={this.toggle}>{this.props.t("Cancel")}</Button>
                        </div>}
                        {this.state.success &&
                            <div className="modal-footer">
                                <Button color={"secondary"} onClick={this.toggle}>{this.props.t("Close")}</Button>
                            </div>}
                    </AvForm>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {}
};

export default withRouter(
    connect(mapStateToProps, {createCar})(withTranslation()(ChangePassword))
);