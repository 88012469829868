import PropTypes from "prop-types";
import React, { Component } from "react";
import {Alert, Card, CardBody, Col, Container, Row} from "reactstrap";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";


// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import {loginUser} from "../../store/auth/actions";
import {withTranslation} from "react-i18next";
import {AvField, AvForm} from "availity-reactstrap-validation";
import {requestPassReset, requestPassReset2, requestPassReset2Check} from "../../helpers/backend_calls";

class ForgetPasswordPage2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
        changed:false,
        checked:false,
        error:false,
        errorMessage:""
    };
  }

  componentDidMount() {
    requestPassReset2Check({email:this.props.match.params.email,hash:this.props.match.params.hash}).then(response=>{
        this.setState({checked:true})
    }).catch(error=>{
        this.setState({error:true,errorMessage:error.response.data.message})
    })
  }

  handleValidSubmit = (event, values) => {
    requestPassReset2({email:this.props.match.params.email,...values}).then(response=>{
      this.setState({changed:true})
    }).catch(error=>{
      this.setState({error:true,errorMessage:error.response.data.message})
    })
  }

  render() {
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="bx bx-home h2" />
          </Link>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">{this.props.t("Password reset")}</h5>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-4">
                    <div>
                      {this.state.error &&
                          <Alert className={"mb-2"} color={"danger"}>{this.props.t(this.state.errorMessage)}</Alert>}
                      {this.state.checked && !this.state.changed &&
                      <AvForm className="form-horizontal"
                              onValidSubmit={this.handleValidSubmit}>
                        <AvField name="newPassword"
                                 label={this.props.t("Password")}
                                 className="form-control"
                                 placeholder={this.props.t("Enter password")}
                                 type="password"
                                 validate={{
                                   pattern:{
                                     value:'^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d).*$',
                                     errorMessage: 'Password must have at least one uppercase, one lowercase letter and one number'
                                   },
                                   minLength: {
                                     value: 8,
                                     errorMessage: 'Your name must be between 8 and 255 characters'
                                   },
                                   maxLength: {
                                     value: 255,
                                     errorMessage: 'Your name must be between 8 and 255 characters'
                                   },
                                   required: {
                                     value: true,
                                     errorMessage: 'Please enter your password'
                                   }
                                 }}
                                 required/>
                        <AvField name="newPasswordConfirm"
                                 label={this.props.t("Password confirmation")}
                                 className="form-control"
                                 placeholder={this.props.t("Enter password confirmation")}
                                 type="password"
                                 validate={{
                                   required: {
                                     value: true,
                                     errorMessage: 'Please enter password confirmation'
                                   },
                                   match: {
                                     value: 'newPassword',
                                     errorMessage: 'Passwords doesn\'t match'
                                   }
                                 }}
                                 required/>

                        <div className="mt-3 d-grid">
                          <button className="btn btn-primary btn-block"
                                  type="submit">{this.props.t("Send")}</button>
                        </div>
                      </AvForm>}
                      {this.state.changed &&
                          <Alert>{this.props.t("Password was changed. You can now log in.")}</Alert>}
                    </div>
                    <div className="p-2">
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    {this.props.t("Go back to")}{" "}
                    <Link to="/login" className="fw-medium text-primary">
                      {this.props.t("Login")}
                    </Link>{" "}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

ForgetPasswordPage2.propTypes = {
  forgetError: PropTypes.func,
  forgetSuccessMsg: PropTypes.string,
  history: PropTypes.object,
  userForgetPassword: PropTypes.any,
};

const mapStateToProps = state => {
};

export default withRouter(
    connect(mapStateToProps, {loginUser})(withTranslation()(ForgetPasswordPage2))
);