import React, {Component} from "react"
import MetaTags from 'react-meta-tags';
import {Alert, Button, Col, Container, Nav, NavItem, NavLink, Row, TabContent, Table, TabPane} from "reactstrap"

// Redux
import {connect} from "react-redux"
import {Link, withRouter} from "react-router-dom"
import packageJson from '../../../package.json';

import {AvField, AvForm} from "availity-reactstrap-validation"


import logo from "../../assets/images/logo.webp";
import {withTranslation} from "react-i18next"
import {loginUser} from "../../store/auth/actions";
import DriverLabel from "../../components/entries/DriverLabel";
import LicenseBadge from "../../components/Licenses/LicenseBadge";
import {getEntriesPublic, getStandings, getStandingsPublic} from "../../helpers/backend_calls";
import classnames from "classnames";
import {ADMIN} from "../../components/Role";
import {getColorBrightness} from "../../components/tools";
import {API_URL, IMAGE_PROVIDER} from "../../helpers/api_helper";

const sstyle ='.entry-container{border:1px solid;height:400px} .class-container{border-bottom:1px solid #74788d;padding:8px;color:white;font-weight:700}.image-container{position:relative;padding:10px;height:200px;overflow:hidden}.image-container img{max-width:100%;max-height:100%}' +
    '.state-container{padding:8px;color:white;font-weight:700;position:absolute;bottom:20px;right:0;}.driver-container{padding:5px;font-size:14px;font-weight:600}.team-container{padding:5px}'

class Entries extends Component {
    constructor(props) {
        super(props)
        this.state = {
            entries:undefined
        }

    }

    componentDidMount() {
        getEntriesPublic({id:this.props.match.params.id}).then(response=>{
            console.log(response)
            this.setState({entries:response})
        }).catch(error=>{
            console.log(error)
        })
    }

    toggle = (tab) => {
        this.setState({
            activeTab: tab
        })
    }


    render() {
        if(this.state.entries===undefined){
            return "LOADING..."
        }
        return (
            <React.Fragment>
                <div>
                    <MetaTags>
                        <title>Digital Motorsport SK | {this.props.t("Entries")}</title>
                    </MetaTags>
                    <style>{sstyle}</style>
                    <Container className="bg-white p-5">
                        <Row>
                            <Col md={12}>
                                <h3>{this.props.t("Entry list")}</h3>
                            </Col>
                            {this.state.entries.map((entry,index)=> {
                                let stateColor = "#818181"
                                if(entry.state==="APPROVED"){
                                    stateColor=entry.carClass.color
                                }
                                if(entry.state==="BANNED"){
                                    stateColor="#000"
                                }
                                //console.log(getColorBrightness(entry.carClass.color))
                                return <Col className={"mt-3"} key={index} lg={4} xl={3}>

                                    <div className={"entry-container"} >
                                        <div className="class-container text-center" style={{background: entry.carClass.color}}>
                                            <Row>
                                                <Col xs={6} className={"text-start"} style={{color:getColorBrightness(entry.carClass.color)}}>{entry.carClass.name}</Col>
                                                <Col xs={6} className={"text-end"} style={{color:getColorBrightness(entry.carClass.color)}}>#{entry.number}</Col>
                                            </Row>
                                        </div>
                                        <div className={"image-container text-center"}>
                                            <img src={API_URL + IMAGE_PROVIDER + entry.image} />
                                            <div className={"state-container"} style={{background: stateColor}}>
                                                <span style={{color:getColorBrightness(stateColor)}}>{this.props.t(entry.state)}</span>
                                            </div>
                                        </div>
                                        <div className={"text-center driver-container"}>
                                            {entry.drivers.map((driver,index)=>{
                                                return <div  key={index}><DriverLabel driver={driver} key={index}/></div>
                                            })}
                                        </div>
                                        <div className={"text-center team-container"}>
                                            {entry.car.name} - {entry.team}
                                        </div>
                                    </div>
                                </Col>
                            })}

                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {}
}

export default withRouter(
    connect(mapStateToProps, {loginUser})(withTranslation()(Entries))
)