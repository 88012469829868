import React, {Component} from "react"
import MetaTags from 'react-meta-tags';
import {Alert, Button, Col, Container, Nav, NavItem, NavLink, Row, TabContent, Table, TabPane} from "reactstrap"

// Redux
import {connect} from "react-redux"
import {Link, withRouter} from "react-router-dom"
import packageJson from '../../../package.json';

import {AvField, AvForm} from "availity-reactstrap-validation"


import logo from "../../assets/images/logo.webp";
import {withTranslation} from "react-i18next"
import {loginUser} from "../../store/auth/actions";
import DriverLabel from "../../components/entries/DriverLabel";
import LicenseBadge from "../../components/Licenses/LicenseBadge";
import {getCalendarPublic, getEntriesPublic, getStandings, getStandingsPublic} from "../../helpers/backend_calls";
import classnames from "classnames";
import {ADMIN} from "../../components/Role";
import {getColorBrightness} from "../../components/tools";
import {API_URL, IMAGE_PROVIDER} from "../../helpers/api_helper";
import moment from "moment/moment";
import DeleteEventButton from "../../components/event/DeleteEventButton";
import AddEvent from "../../components/series/AddEvent";

class Entries extends Component {
    constructor(props) {
        super(props)
        this.state = {
            calendar:undefined
        }

    }

    componentDidMount() {
        getCalendarPublic({id:this.props.match.params.id}).then(response=>{
            this.setState({calendar:response})
        }).catch(error=>{
            console.log(error)
        })
    }

    toggle = (tab) => {
        this.setState({
            activeTab: tab
        })
    }


    render() {
        if(this.state.calendar===undefined){
            return "LOADING..."
        }
        return (
            <React.Fragment>
                <div>
                    <MetaTags>
                        <title>Digital Motorsport SK | {this.props.t("Events")}</title>
                    </MetaTags>
                    <Container className="bg-white p-5">
                        <Row>
                            <Col md={12}>
                                <div className="table-responsive">
                                    <Table
                                        className="table table-hover table-stripped table-bordered mb-0">
                                        <thead>
                                        <tr>
                                            <th>{this.props.t("Name")}</th>
                                            <th >{this.props.t("Practice start")}</th>
                                            <th >{this.props.t("Qualification start")}</th>
                                            <th >{this.props.t("Race start")}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.calendar.map((event,index)=> {
                                            let dateRaceStart = new Date(event.raceStart);
                                            let dateQualifyStart = new Date(event.qualifyStart);
                                            let datePracticeStart = new Date(event.practiceStart);
                                            return(
                                                <tr key={index}>
                                                    <td>
                                                        {event.name}
                                                    </td>
                                                    <td>{moment(event.practiceStart).utc(false).format("D. M. yyyy")} - <b>{moment(event.practiceStart).utc(false).format("HH:mm")}</b></td>
                                                    <td>{moment(event.qualifyStart).utc(false).format("D. M. yyyy")} - <b>{moment(event.qualifyStart).utc(false).format("HH:mm")}</b></td>
                                                    <td>{moment(event.raceStart).utc(false).format("D. M. yyyy")} - <b>{moment(event.raceStart).utc(false).format("HH:mm")}</b></td>

                                                </tr>
                                            )}
                                        )}

                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {}
}

export default withRouter(
    connect(mapStateToProps, {loginUser})(withTranslation()(Entries))
)