//REGISTER
export const LOGIN = "/api/v1/login"
export const REQUEST_RESET_PASS = "/api/v1/request-reset-password"
export const REGISTER = "/api/v1/sign-up"
export const USER = "/api/v1/users"
export const SERIES = "/api/v1/series"
export const SCORING = "/api/v1/scoring"
export const EVENT = "/api/v1/events"
export const ENTRY = "/api/v1/entries"
export const LICENSE = "/api/v1/licenses"
export const CARS = "/api/v1/cars"
export const CAR_CLASS = "/api/v1/car-class"
export const PUBLIC = "/api/v1/public"
