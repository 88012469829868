export const GET_ALL_SERIES = "GET_ALL_SERIES"
export const SET_ALL_SERIES = "SET_ALL_SERIES"
export const CREATE_SERIES = "CREATE_SERIES"
export const UPDATE_SERIES = "UPDATE_SERIES"
export const DELETE_SERIES = "DELETE_SERIES"
export const GET_SERIES_DETAIL = "GET_SERIES_DETAIL"
export const SET_SERIES_DETAIL = "SET_SERIES_DETAIL"
export const CREATE_CAR_CLASS = "CREATE_CAR_CLASS"
export const UPDATE_CAR_CLASS = "UPDATE_CAR_CLASS"
export const DELETE_CAR_CLASS = "DELETE_CAR_CLASS"
export const ADD_CAR_CLASS = "ADD_CAR_CLASS"
export const REMOVE_CAR_CLASS = "REMOVE_CAR_CLASS"
export const GET_STANDINGS = "GET_STANDINGS"
export const SET_STANDINGS = "SET_STANDINGS"
export const UPLOAD_SERIES_IMAGE = "UPLOAD_SERIES_IMAGE"

