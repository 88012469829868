import PropTypes from "prop-types";
import React, { Component } from "react";
import {Alert, Card, CardBody, Col, Container, Row} from "reactstrap";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";


// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import {loginUser} from "../../store/auth/actions";
import {withTranslation} from "react-i18next";
import {AvField, AvForm} from "availity-reactstrap-validation";
import {requestPassReset} from "../../helpers/backend_calls";

class ForgetPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
        sent:false,
        error:false,
        errorMessage:""
    };
  }

  handleValidSubmit = (event, values) => {
    requestPassReset({...values}).then(response=> {
      this.setState({error:false,errorMessage:"",sent:true})
    }).catch(error=> {
      this.setState({error:true,errorMessage:error.response.data.message,sent:false})
    })
  }

  render() {
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="bx bx-home h2" />
          </Link>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">{this.props.t("Forgot password?")}</h5>
                          <p>{this.props.t("Enter email")}</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-4">
                    <div>
                      {!this.state.sent &&
                      <AvForm className="form-horizontal"
                              onValidSubmit={this.handleValidSubmit}>
                        {this.state.error &&
                            <Alert className={"mb-2"} color={"danger"}>{this.props.t(this.state.errorMessage)}</Alert>}
                        <AvField name="email"
                                 label={this.props.t("Email")}
                                 value=""
                                 className="form-control"
                                 placeholder={this.props.t("Enter email")}
                                 type="email"
                                 required
                        />

                        <div className="mt-3 d-grid">
                          <button className="btn btn-primary btn-block"
                                  type="submit">{this.props.t("Send")}</button>
                        </div>
                      </AvForm>}
                      {this.state.sent &&
                          <Alert>{this.props.t("Proceed according to the email you received")}</Alert>}
                    </div>
                    <div className="p-2">
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    {this.props.t("Go back to")}{" "}
                    <Link to="login" className="fw-medium text-primary">
                      {this.props.t("Login")}
                    </Link>{" "}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

ForgetPasswordPage.propTypes = {
  forgetError: PropTypes.func,
  forgetSuccessMsg: PropTypes.string,
  history: PropTypes.object,
  userForgetPassword: PropTypes.any,
};

const mapStateToProps = state => {
};

export default withRouter(
    connect(mapStateToProps, {loginUser})(withTranslation()(ForgetPasswordPage))
);