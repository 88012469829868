import React, {Component} from "react"
import MetaTags from 'react-meta-tags';
import {Alert, Col, Container, Nav, NavItem, NavLink, Row, TabContent, Table, TabPane} from "reactstrap"

// Redux
import {connect} from "react-redux"
import {Link, withRouter} from "react-router-dom"
import packageJson from '../../../package.json';

import {AvField, AvForm} from "availity-reactstrap-validation"


import logo from "../../assets/images/logo.webp";
import {withTranslation} from "react-i18next"
import {loginUser} from "../../store/auth/actions";
import DriverLabel from "../../components/entries/DriverLabel";
import LicenseBadge from "../../components/Licenses/LicenseBadge";
import {getStandings, getStandingsPublic} from "../../helpers/backend_calls";
import classnames from "classnames";

class Standings extends Component {
    constructor(props) {
        super(props)
        this.state = {
            standings:undefined,
            activeTab: "tab0"
        }

    }

    componentDidMount() {
        console.log(this.props.match.params.id)
        getStandingsPublic({id:this.props.match.params.id}).then(response=>{
            this.setState({standings:response})
        }).catch(error=>{
            console.log(error)
        })
    }

    toggle = (tab) => {
        this.setState({
            activeTab: tab
        })
    }


    render() {
        if(this.state.standings===undefined){
            return "LOADING..."
        }
        return (
            <React.Fragment>
                <div>
                    <MetaTags>
                        <title>Digital Motorsport SK | {this.props.t("Standings")}</title>
                    </MetaTags>
                    <Container className="bg-white p-5">
                        <Row>
                            <Col md="3">
                                <Nav pills className="flex-column">
                                    {this.state.standings.standings.map((standings, index)=>
                                        <NavItem key={index}>
                                            <NavLink
                                                style={{cursor: "pointer"}}
                                                className={classnames({
                                                    'mb-2': true,
                                                    active: this.state.activeTab === "tab"+index,
                                                })}
                                                onClick={() => this.toggle("tab"+index)}
                                            >
                                                {this.props.t(standings.name)}
                                            </NavLink>
                                        </NavItem>
                                    )}
                                </Nav>
                            </Col>
                            <Col md="9">
                                <TabContent activeTab={this.state.activeTab}  className="text-muted mt-4 mt-md-0">
                                    {this.state.standings.standings.map((standings, index)=>
                                        <TabPane key={index} tabId={"tab"+index}>
                                            <div className={"table-responsive"}>
                                                <Table
                                                    className="table table-hover table-stripped table-bordered mb-0">
                                                    <thead>
                                                    <tr>
                                                        <th width={50}>#</th>
                                                        <th>{this.props.t("Drivers")}</th>
                                                        <th>{this.props.t("Team name")}</th>
                                                        <th>{this.props.t("Class")}</th>
                                                        <th>{this.props.t("Car")}</th>
                                                        {standings.events.map((event,index)=>{
                                                            return <th width={50} key={index}>{event.code}</th>
                                                        })}
                                                        <th width={50}>{this.props.t("Total")}</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {standings.rows.map((row,index)=>
                                                        <tr key={index}>
                                                            <td>{index+1}.</td>
                                                            <td>
                                                                {row.drivers.map((driver,index)=>
                                                                    <div  key={index}>
                                                                        <DriverLabel driver={driver} />
                                                                    </div>
                                                                )}
                                                            </td>
                                                            <td>{row.team}</td>
                                                            <td><LicenseBadge name={row.carClass.name} color={row.carClass.color} /></td>
                                                            <td>{row.car.name}</td>
                                                            {row.eventPoints.map((event,index)=>{
                                                                return <td key={index}>{event.points}</td>
                                                            })}
                                                            <td>{row.points}</td>
                                                        </tr>
                                                    )}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </TabPane>
                                    )}
                                </TabContent>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {}
}

export default withRouter(
    connect(mapStateToProps, {loginUser})(withTranslation()(Standings))
)